var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

// https://d3js.org/d3-selection/ Version 1.1.0. Copyright 2017 Mike Bostock.
(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  var xhtml = "http://www.w3.org/1999/xhtml";
  var namespaces = {
    svg: "http://www.w3.org/2000/svg",
    xhtml: xhtml,
    xlink: "http://www.w3.org/1999/xlink",
    xml: "http://www.w3.org/XML/1998/namespace",
    xmlns: "http://www.w3.org/2000/xmlns/"
  };

  var namespace = function (name) {
    var prefix = name += "",
        i = prefix.indexOf(":");
    if (i >= 0 && (prefix = name.slice(0, i)) !== "xmlns") name = name.slice(i + 1);
    return namespaces.hasOwnProperty(prefix) ? {
      space: namespaces[prefix],
      local: name
    } : name;
  };

  function creatorInherit(name) {
    return function () {
      var document = (this || _global).ownerDocument,
          uri = (this || _global).namespaceURI;
      return uri === xhtml && document.documentElement.namespaceURI === xhtml ? document.createElement(name) : document.createElementNS(uri, name);
    };
  }

  function creatorFixed(fullname) {
    return function () {
      return (this || _global).ownerDocument.createElementNS(fullname.space, fullname.local);
    };
  }

  var creator = function (name) {
    var fullname = namespace(name);
    return (fullname.local ? creatorFixed : creatorInherit)(fullname);
  };

  var nextId = 0;

  function local() {
    return new Local();
  }

  function Local() {
    (this || _global)._ = "@" + (++nextId).toString(36);
  }

  Local.prototype = local.prototype = {
    constructor: Local,
    get: function (node) {
      var id = (this || _global)._;

      while (!(id in node)) if (!(node = node.parentNode)) return;

      return node[id];
    },
    set: function (node, value) {
      return node[(this || _global)._] = value;
    },
    remove: function (node) {
      return (this || _global)._ in node && delete node[(this || _global)._];
    },
    toString: function () {
      return (this || _global)._;
    }
  };

  var matcher = function (selector) {
    return function () {
      return this.matches(selector);
    };
  };

  if (typeof document !== "undefined") {
    var element = document.documentElement;

    if (!element.matches) {
      var vendorMatches = element.webkitMatchesSelector || element.msMatchesSelector || element.mozMatchesSelector || element.oMatchesSelector;

      matcher = function (selector) {
        return function () {
          return vendorMatches.call(this || _global, selector);
        };
      };
    }
  }

  var matcher$1 = matcher;
  var filterEvents = {};
  exports.event = null;

  if (typeof document !== "undefined") {
    var element$1 = document.documentElement;

    if (!("onmouseenter" in element$1)) {
      filterEvents = {
        mouseenter: "mouseover",
        mouseleave: "mouseout"
      };
    }
  }

  function filterContextListener(listener, index, group) {
    listener = contextListener(listener, index, group);
    return function (event) {
      var related = event.relatedTarget;

      if (!related || related !== (this || _global) && !(related.compareDocumentPosition(this || _global) & 8)) {
        listener.call(this || _global, event);
      }
    };
  }

  function contextListener(listener, index, group) {
    return function (event1) {
      var event0 = exports.event; // Events can be reentrant (e.g., focus).

      exports.event = event1;

      try {
        listener.call(this || _global, (this || _global).__data__, index, group);
      } finally {
        exports.event = event0;
      }
    };
  }

  function parseTypenames(typenames) {
    return typenames.trim().split(/^|\s+/).map(function (t) {
      var name = "",
          i = t.indexOf(".");
      if (i >= 0) name = t.slice(i + 1), t = t.slice(0, i);
      return {
        type: t,
        name: name
      };
    });
  }

  function onRemove(typename) {
    return function () {
      var on = (this || _global).__on;
      if (!on) return;

      for (var j = 0, i = -1, m = on.length, o; j < m; ++j) {
        if (o = on[j], (!typename.type || o.type === typename.type) && o.name === typename.name) {
          this.removeEventListener(o.type, o.listener, o.capture);
        } else {
          on[++i] = o;
        }
      }

      if (++i) on.length = i;else delete (this || _global).__on;
    };
  }

  function onAdd(typename, value, capture) {
    var wrap = filterEvents.hasOwnProperty(typename.type) ? filterContextListener : contextListener;
    return function (d, i, group) {
      var on = (this || _global).__on,
          o,
          listener = wrap(value, i, group);
      if (on) for (var j = 0, m = on.length; j < m; ++j) {
        if ((o = on[j]).type === typename.type && o.name === typename.name) {
          this.removeEventListener(o.type, o.listener, o.capture);
          this.addEventListener(o.type, o.listener = listener, o.capture = capture);
          o.value = value;
          return;
        }
      }
      this.addEventListener(typename.type, listener, capture);
      o = {
        type: typename.type,
        name: typename.name,
        value: value,
        listener: listener,
        capture: capture
      };
      if (!on) (this || _global).__on = [o];else on.push(o);
    };
  }

  var selection_on = function (typename, value, capture) {
    var typenames = parseTypenames(typename + ""),
        i,
        n = typenames.length,
        t;

    if (arguments.length < 2) {
      var on = this.node().__on;

      if (on) for (var j = 0, m = on.length, o; j < m; ++j) {
        for (i = 0, o = on[j]; i < n; ++i) {
          if ((t = typenames[i]).type === o.type && t.name === o.name) {
            return o.value;
          }
        }
      }
      return;
    }

    on = value ? onAdd : onRemove;
    if (capture == null) capture = false;

    for (i = 0; i < n; ++i) this.each(on(typenames[i], value, capture));

    return this || _global;
  };

  function customEvent(event1, listener, that, args) {
    var event0 = exports.event;
    event1.sourceEvent = exports.event;
    exports.event = event1;

    try {
      return listener.apply(that, args);
    } finally {
      exports.event = event0;
    }
  }

  var sourceEvent = function () {
    var current = exports.event,
        source;

    while (source = current.sourceEvent) current = source;

    return current;
  };

  var point = function (node, event) {
    var svg = node.ownerSVGElement || node;

    if (svg.createSVGPoint) {
      var point = svg.createSVGPoint();
      point.x = event.clientX, point.y = event.clientY;
      point = point.matrixTransform(node.getScreenCTM().inverse());
      return [point.x, point.y];
    }

    var rect = node.getBoundingClientRect();
    return [event.clientX - rect.left - node.clientLeft, event.clientY - rect.top - node.clientTop];
  };

  var mouse = function (node) {
    var event = sourceEvent();
    if (event.changedTouches) event = event.changedTouches[0];
    return point(node, event);
  };

  function none() {}

  var selector = function (selector) {
    return selector == null ? none : function () {
      return this.querySelector(selector);
    };
  };

  var selection_select = function (select) {
    if (typeof select !== "function") select = selector(select);

    for (var groups = (this || _global)._groups, m = groups.length, subgroups = new Array(m), j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, subgroup = subgroups[j] = new Array(n), node, subnode, i = 0; i < n; ++i) {
        if ((node = group[i]) && (subnode = select.call(node, node.__data__, i, group))) {
          if ("__data__" in node) subnode.__data__ = node.__data__;
          subgroup[i] = subnode;
        }
      }
    }

    return new Selection(subgroups, (this || _global)._parents);
  };

  function empty() {
    return [];
  }

  var selectorAll = function (selector) {
    return selector == null ? empty : function () {
      return this.querySelectorAll(selector);
    };
  };

  var selection_selectAll = function (select) {
    if (typeof select !== "function") select = selectorAll(select);

    for (var groups = (this || _global)._groups, m = groups.length, subgroups = [], parents = [], j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, node, i = 0; i < n; ++i) {
        if (node = group[i]) {
          subgroups.push(select.call(node, node.__data__, i, group));
          parents.push(node);
        }
      }
    }

    return new Selection(subgroups, parents);
  };

  var selection_filter = function (match) {
    if (typeof match !== "function") match = matcher$1(match);

    for (var groups = (this || _global)._groups, m = groups.length, subgroups = new Array(m), j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, subgroup = subgroups[j] = [], node, i = 0; i < n; ++i) {
        if ((node = group[i]) && match.call(node, node.__data__, i, group)) {
          subgroup.push(node);
        }
      }
    }

    return new Selection(subgroups, (this || _global)._parents);
  };

  var sparse = function (update) {
    return new Array(update.length);
  };

  var selection_enter = function () {
    return new Selection((this || _global)._enter || (this || _global)._groups.map(sparse), (this || _global)._parents);
  };

  function EnterNode(parent, datum) {
    (this || _global).ownerDocument = parent.ownerDocument;
    (this || _global).namespaceURI = parent.namespaceURI;
    (this || _global)._next = null;
    (this || _global)._parent = parent;
    (this || _global).__data__ = datum;
  }

  EnterNode.prototype = {
    constructor: EnterNode,
    appendChild: function (child) {
      return (this || _global)._parent.insertBefore(child, (this || _global)._next);
    },
    insertBefore: function (child, next) {
      return (this || _global)._parent.insertBefore(child, next);
    },
    querySelector: function (selector) {
      return (this || _global)._parent.querySelector(selector);
    },
    querySelectorAll: function (selector) {
      return (this || _global)._parent.querySelectorAll(selector);
    }
  };

  var constant = function (x) {
    return function () {
      return x;
    };
  };

  var keyPrefix = "$"; // Protect against keys like “__proto__”.

  function bindIndex(parent, group, enter, update, exit, data) {
    var i = 0,
        node,
        groupLength = group.length,
        dataLength = data.length; // Put any non-null nodes that fit into update.
    // Put any null nodes into enter.
    // Put any remaining data into enter.

    for (; i < dataLength; ++i) {
      if (node = group[i]) {
        node.__data__ = data[i];
        update[i] = node;
      } else {
        enter[i] = new EnterNode(parent, data[i]);
      }
    } // Put any non-null nodes that don’t fit into exit.


    for (; i < groupLength; ++i) {
      if (node = group[i]) {
        exit[i] = node;
      }
    }
  }

  function bindKey(parent, group, enter, update, exit, data, key) {
    var i,
        node,
        nodeByKeyValue = {},
        groupLength = group.length,
        dataLength = data.length,
        keyValues = new Array(groupLength),
        keyValue; // Compute the key for each node.
    // If multiple nodes have the same key, the duplicates are added to exit.

    for (i = 0; i < groupLength; ++i) {
      if (node = group[i]) {
        keyValues[i] = keyValue = keyPrefix + key.call(node, node.__data__, i, group);

        if (keyValue in nodeByKeyValue) {
          exit[i] = node;
        } else {
          nodeByKeyValue[keyValue] = node;
        }
      }
    } // Compute the key for each datum.
    // If there a node associated with this key, join and add it to update.
    // If there is not (or the key is a duplicate), add it to enter.


    for (i = 0; i < dataLength; ++i) {
      keyValue = keyPrefix + key.call(parent, data[i], i, data);

      if (node = nodeByKeyValue[keyValue]) {
        update[i] = node;
        node.__data__ = data[i];
        nodeByKeyValue[keyValue] = null;
      } else {
        enter[i] = new EnterNode(parent, data[i]);
      }
    } // Add any remaining nodes that were not bound to data to exit.


    for (i = 0; i < groupLength; ++i) {
      if ((node = group[i]) && nodeByKeyValue[keyValues[i]] === node) {
        exit[i] = node;
      }
    }
  }

  var selection_data = function (value, key) {
    if (!value) {
      data = new Array(this.size()), j = -1;
      this.each(function (d) {
        data[++j] = d;
      });
      return data;
    }

    var bind = key ? bindKey : bindIndex,
        parents = (this || _global)._parents,
        groups = (this || _global)._groups;
    if (typeof value !== "function") value = constant(value);

    for (var m = groups.length, update = new Array(m), enter = new Array(m), exit = new Array(m), j = 0; j < m; ++j) {
      var parent = parents[j],
          group = groups[j],
          groupLength = group.length,
          data = value.call(parent, parent && parent.__data__, j, parents),
          dataLength = data.length,
          enterGroup = enter[j] = new Array(dataLength),
          updateGroup = update[j] = new Array(dataLength),
          exitGroup = exit[j] = new Array(groupLength);
      bind(parent, group, enterGroup, updateGroup, exitGroup, data, key); // Now connect the enter nodes to their following update node, such that
      // appendChild can insert the materialized enter node before this node,
      // rather than at the end of the parent node.

      for (var i0 = 0, i1 = 0, previous, next; i0 < dataLength; ++i0) {
        if (previous = enterGroup[i0]) {
          if (i0 >= i1) i1 = i0 + 1;

          while (!(next = updateGroup[i1]) && ++i1 < dataLength);

          previous._next = next || null;
        }
      }
    }

    update = new Selection(update, parents);
    update._enter = enter;
    update._exit = exit;
    return update;
  };

  var selection_exit = function () {
    return new Selection((this || _global)._exit || (this || _global)._groups.map(sparse), (this || _global)._parents);
  };

  var selection_merge = function (selection) {
    for (var groups0 = (this || _global)._groups, groups1 = selection._groups, m0 = groups0.length, m1 = groups1.length, m = Math.min(m0, m1), merges = new Array(m0), j = 0; j < m; ++j) {
      for (var group0 = groups0[j], group1 = groups1[j], n = group0.length, merge = merges[j] = new Array(n), node, i = 0; i < n; ++i) {
        if (node = group0[i] || group1[i]) {
          merge[i] = node;
        }
      }
    }

    for (; j < m0; ++j) {
      merges[j] = groups0[j];
    }

    return new Selection(merges, (this || _global)._parents);
  };

  var selection_order = function () {
    for (var groups = (this || _global)._groups, j = -1, m = groups.length; ++j < m;) {
      for (var group = groups[j], i = group.length - 1, next = group[i], node; --i >= 0;) {
        if (node = group[i]) {
          if (next && next !== node.nextSibling) next.parentNode.insertBefore(node, next);
          next = node;
        }
      }
    }

    return this || _global;
  };

  var selection_sort = function (compare) {
    if (!compare) compare = ascending;

    function compareNode(a, b) {
      return a && b ? compare(a.__data__, b.__data__) : !a - !b;
    }

    for (var groups = (this || _global)._groups, m = groups.length, sortgroups = new Array(m), j = 0; j < m; ++j) {
      for (var group = groups[j], n = group.length, sortgroup = sortgroups[j] = new Array(n), node, i = 0; i < n; ++i) {
        if (node = group[i]) {
          sortgroup[i] = node;
        }
      }

      sortgroup.sort(compareNode);
    }

    return new Selection(sortgroups, (this || _global)._parents).order();
  };

  function ascending(a, b) {
    return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
  }

  var selection_call = function () {
    var callback = arguments[0];
    arguments[0] = this || _global;
    callback.apply(null, arguments);
    return this || _global;
  };

  var selection_nodes = function () {
    var nodes = new Array(this.size()),
        i = -1;
    this.each(function () {
      nodes[++i] = this || _global;
    });
    return nodes;
  };

  var selection_node = function () {
    for (var groups = (this || _global)._groups, j = 0, m = groups.length; j < m; ++j) {
      for (var group = groups[j], i = 0, n = group.length; i < n; ++i) {
        var node = group[i];
        if (node) return node;
      }
    }

    return null;
  };

  var selection_size = function () {
    var size = 0;
    this.each(function () {
      ++size;
    });
    return size;
  };

  var selection_empty = function () {
    return !this.node();
  };

  var selection_each = function (callback) {
    for (var groups = (this || _global)._groups, j = 0, m = groups.length; j < m; ++j) {
      for (var group = groups[j], i = 0, n = group.length, node; i < n; ++i) {
        if (node = group[i]) callback.call(node, node.__data__, i, group);
      }
    }

    return this || _global;
  };

  function attrRemove(name) {
    return function () {
      this.removeAttribute(name);
    };
  }

  function attrRemoveNS(fullname) {
    return function () {
      this.removeAttributeNS(fullname.space, fullname.local);
    };
  }

  function attrConstant(name, value) {
    return function () {
      this.setAttribute(name, value);
    };
  }

  function attrConstantNS(fullname, value) {
    return function () {
      this.setAttributeNS(fullname.space, fullname.local, value);
    };
  }

  function attrFunction(name, value) {
    return function () {
      var v = value.apply(this || _global, arguments);
      if (v == null) this.removeAttribute(name);else this.setAttribute(name, v);
    };
  }

  function attrFunctionNS(fullname, value) {
    return function () {
      var v = value.apply(this || _global, arguments);
      if (v == null) this.removeAttributeNS(fullname.space, fullname.local);else this.setAttributeNS(fullname.space, fullname.local, v);
    };
  }

  var selection_attr = function (name, value) {
    var fullname = namespace(name);

    if (arguments.length < 2) {
      var node = this.node();
      return fullname.local ? node.getAttributeNS(fullname.space, fullname.local) : node.getAttribute(fullname);
    }

    return this.each((value == null ? fullname.local ? attrRemoveNS : attrRemove : typeof value === "function" ? fullname.local ? attrFunctionNS : attrFunction : fullname.local ? attrConstantNS : attrConstant)(fullname, value));
  };

  var defaultView = function (node) {
    return node.ownerDocument && node.ownerDocument.defaultView || // node is a Node
    node.document && node // node is a Window
    || node.defaultView; // node is a Document
  };

  function styleRemove(name) {
    return function () {
      (this || _global).style.removeProperty(name);
    };
  }

  function styleConstant(name, value, priority) {
    return function () {
      (this || _global).style.setProperty(name, value, priority);
    };
  }

  function styleFunction(name, value, priority) {
    return function () {
      var v = value.apply(this || _global, arguments);
      if (v == null) (this || _global).style.removeProperty(name);else (this || _global).style.setProperty(name, v, priority);
    };
  }

  var selection_style = function (name, value, priority) {
    return arguments.length > 1 ? this.each((value == null ? styleRemove : typeof value === "function" ? styleFunction : styleConstant)(name, value, priority == null ? "" : priority)) : styleValue(this.node(), name);
  };

  function styleValue(node, name) {
    return node.style.getPropertyValue(name) || defaultView(node).getComputedStyle(node, null).getPropertyValue(name);
  }

  function propertyRemove(name) {
    return function () {
      delete (this || _global)[name];
    };
  }

  function propertyConstant(name, value) {
    return function () {
      (this || _global)[name] = value;
    };
  }

  function propertyFunction(name, value) {
    return function () {
      var v = value.apply(this || _global, arguments);
      if (v == null) delete (this || _global)[name];else (this || _global)[name] = v;
    };
  }

  var selection_property = function (name, value) {
    return arguments.length > 1 ? this.each((value == null ? propertyRemove : typeof value === "function" ? propertyFunction : propertyConstant)(name, value)) : this.node()[name];
  };

  function classArray(string) {
    return string.trim().split(/^|\s+/);
  }

  function classList(node) {
    return node.classList || new ClassList(node);
  }

  function ClassList(node) {
    (this || _global)._node = node;
    (this || _global)._names = classArray(node.getAttribute("class") || "");
  }

  ClassList.prototype = {
    add: function (name) {
      var i = (this || _global)._names.indexOf(name);

      if (i < 0) {
        (this || _global)._names.push(name);

        (this || _global)._node.setAttribute("class", (this || _global)._names.join(" "));
      }
    },
    remove: function (name) {
      var i = (this || _global)._names.indexOf(name);

      if (i >= 0) {
        (this || _global)._names.splice(i, 1);

        (this || _global)._node.setAttribute("class", (this || _global)._names.join(" "));
      }
    },
    contains: function (name) {
      return (this || _global)._names.indexOf(name) >= 0;
    }
  };

  function classedAdd(node, names) {
    var list = classList(node),
        i = -1,
        n = names.length;

    while (++i < n) list.add(names[i]);
  }

  function classedRemove(node, names) {
    var list = classList(node),
        i = -1,
        n = names.length;

    while (++i < n) list.remove(names[i]);
  }

  function classedTrue(names) {
    return function () {
      classedAdd(this || _global, names);
    };
  }

  function classedFalse(names) {
    return function () {
      classedRemove(this || _global, names);
    };
  }

  function classedFunction(names, value) {
    return function () {
      (value.apply(this || _global, arguments) ? classedAdd : classedRemove)(this || _global, names);
    };
  }

  var selection_classed = function (name, value) {
    var names = classArray(name + "");

    if (arguments.length < 2) {
      var list = classList(this.node()),
          i = -1,
          n = names.length;

      while (++i < n) if (!list.contains(names[i])) return false;

      return true;
    }

    return this.each((typeof value === "function" ? classedFunction : value ? classedTrue : classedFalse)(names, value));
  };

  function textRemove() {
    (this || _global).textContent = "";
  }

  function textConstant(value) {
    return function () {
      (this || _global).textContent = value;
    };
  }

  function textFunction(value) {
    return function () {
      var v = value.apply(this || _global, arguments);
      (this || _global).textContent = v == null ? "" : v;
    };
  }

  var selection_text = function (value) {
    return arguments.length ? this.each(value == null ? textRemove : (typeof value === "function" ? textFunction : textConstant)(value)) : this.node().textContent;
  };

  function htmlRemove() {
    (this || _global).innerHTML = "";
  }

  function htmlConstant(value) {
    return function () {
      (this || _global).innerHTML = value;
    };
  }

  function htmlFunction(value) {
    return function () {
      var v = value.apply(this || _global, arguments);
      (this || _global).innerHTML = v == null ? "" : v;
    };
  }

  var selection_html = function (value) {
    return arguments.length ? this.each(value == null ? htmlRemove : (typeof value === "function" ? htmlFunction : htmlConstant)(value)) : this.node().innerHTML;
  };

  function raise() {
    if ((this || _global).nextSibling) (this || _global).parentNode.appendChild(this || _global);
  }

  var selection_raise = function () {
    return this.each(raise);
  };

  function lower() {
    if ((this || _global).previousSibling) (this || _global).parentNode.insertBefore(this || _global, (this || _global).parentNode.firstChild);
  }

  var selection_lower = function () {
    return this.each(lower);
  };

  var selection_append = function (name) {
    var create = typeof name === "function" ? name : creator(name);
    return this.select(function () {
      return this.appendChild(create.apply(this || _global, arguments));
    });
  };

  function constantNull() {
    return null;
  }

  var selection_insert = function (name, before) {
    var create = typeof name === "function" ? name : creator(name),
        select = before == null ? constantNull : typeof before === "function" ? before : selector(before);
    return this.select(function () {
      return this.insertBefore(create.apply(this || _global, arguments), select.apply(this || _global, arguments) || null);
    });
  };

  function remove() {
    var parent = (this || _global).parentNode;
    if (parent) parent.removeChild(this || _global);
  }

  var selection_remove = function () {
    return this.each(remove);
  };

  var selection_datum = function (value) {
    return arguments.length ? this.property("__data__", value) : this.node().__data__;
  };

  function dispatchEvent(node, type, params) {
    var window = defaultView(node),
        event = window.CustomEvent;

    if (typeof event === "function") {
      event = new event(type, params);
    } else {
      event = window.document.createEvent("Event");
      if (params) event.initEvent(type, params.bubbles, params.cancelable), event.detail = params.detail;else event.initEvent(type, false, false);
    }

    node.dispatchEvent(event);
  }

  function dispatchConstant(type, params) {
    return function () {
      return dispatchEvent(this || _global, type, params);
    };
  }

  function dispatchFunction(type, params) {
    return function () {
      return dispatchEvent(this || _global, type, params.apply(this || _global, arguments));
    };
  }

  var selection_dispatch = function (type, params) {
    return this.each((typeof params === "function" ? dispatchFunction : dispatchConstant)(type, params));
  };

  var root = [null];

  function Selection(groups, parents) {
    (this || _global)._groups = groups;
    (this || _global)._parents = parents;
  }

  function selection() {
    return new Selection([[document.documentElement]], root);
  }

  Selection.prototype = selection.prototype = {
    constructor: Selection,
    select: selection_select,
    selectAll: selection_selectAll,
    filter: selection_filter,
    data: selection_data,
    enter: selection_enter,
    exit: selection_exit,
    merge: selection_merge,
    order: selection_order,
    sort: selection_sort,
    call: selection_call,
    nodes: selection_nodes,
    node: selection_node,
    size: selection_size,
    empty: selection_empty,
    each: selection_each,
    attr: selection_attr,
    style: selection_style,
    property: selection_property,
    classed: selection_classed,
    text: selection_text,
    html: selection_html,
    raise: selection_raise,
    lower: selection_lower,
    append: selection_append,
    insert: selection_insert,
    remove: selection_remove,
    datum: selection_datum,
    on: selection_on,
    dispatch: selection_dispatch
  };

  var select = function (selector) {
    return typeof selector === "string" ? new Selection([[document.querySelector(selector)]], [document.documentElement]) : new Selection([[selector]], root);
  };

  var selectAll = function (selector) {
    return typeof selector === "string" ? new Selection([document.querySelectorAll(selector)], [document.documentElement]) : new Selection([selector == null ? [] : selector], root);
  };

  var touch = function (node, touches, identifier) {
    if (arguments.length < 3) identifier = touches, touches = sourceEvent().changedTouches;

    for (var i = 0, n = touches ? touches.length : 0, touch; i < n; ++i) {
      if ((touch = touches[i]).identifier === identifier) {
        return point(node, touch);
      }
    }

    return null;
  };

  var touches = function (node, touches) {
    if (touches == null) touches = sourceEvent().touches;

    for (var i = 0, n = touches ? touches.length : 0, points = new Array(n); i < n; ++i) {
      points[i] = point(node, touches[i]);
    }

    return points;
  };

  exports.creator = creator;
  exports.local = local;
  exports.matcher = matcher$1;
  exports.mouse = mouse;
  exports.namespace = namespace;
  exports.namespaces = namespaces;
  exports.select = select;
  exports.selectAll = selectAll;
  exports.selection = selection;
  exports.selector = selector;
  exports.selectorAll = selectorAll;
  exports.style = styleValue;
  exports.touch = touch;
  exports.touches = touches;
  exports.window = defaultView;
  exports.customEvent = customEvent;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const event = exports.event,
      creator = exports.creator,
      local = exports.local,
      matcher = exports.matcher,
      mouse = exports.mouse,
      namespace = exports.namespace,
      namespaces = exports.namespaces,
      select = exports.select,
      selectAll = exports.selectAll,
      selection = exports.selection,
      selector = exports.selector,
      selectorAll = exports.selectorAll,
      style = exports.style,
      touch = exports.touch,
      touches = exports.touches,
      window = exports.window,
      customEvent = exports.customEvent,
      __esModule = exports.__esModule;